import ShipToBox from '~/public/assets/images/news/ship-to-box.png'
import PayToMeNews from '~/public/assets/images/news/pay-to-me.png'
import Partnership from '~/public/assets/images/news/partnership.png'
import WiningAward from '~/public/assets/images/news/steve-award-2023.png'
import SteveAwards from '~/public/assets/images/news/steve-awards-2024.png'
import NilsonReport from '~/public/assets/images/news/nilson-report-new.png'
import GlobalExpension from '~/public/assets/images/news/global-expension.png'
import PlaidIntegration from '~/public/assets/images/news/plaid-integration.png'
import StevieAugust2024 from '~/public/assets/images/news/stevie-august-2024.png'
import FinovateSpring from '~/public/assets/images/news/innovative-financial-technologies.png'

export const PTM_NEWS = [
  {
    title:
      'PayToMe.co, a FinTech innovator, won Silver for Technical Innovation at the 2024 Stevie Awards, highlighting its AI advancements.',
    date: 'August 21, 2024',
    blog_url: `https://www.prnewswire.com/news-releases/paytomeco-honored-with-stevie-silver-award-for-technology-excellence-302226679.html`,
    img_src: StevieAugust2024
  },
  {
    title: 'PayToMe.co to Demo Innovative Financial Technologies at FinovateSpring 2024.',
    date: 'May 14, 2024',
    blog_url: `https://www.prnewswire.com/news-releases/paytomeco-to-demo-innovative-financial-technologies-at-finovatespring-2024-302144950.html`,
    img_src: FinovateSpring
  },
  {
    title:
      'PayToMe.co, a fintech company, achieves six Stevie Awards for AI and tech excellence in American Business Awards in 2024.',
    date: 'May 1, 2024',
    blog_url: `https://apptechcorp.com/press-release/apptech-payments-strategic-partner-paytome-co-honored-with-six-american-business-awards-for-ai-and-technological-excellence/`,
    img_src: SteveAwards
  },
  {
    title:
      'PayToMe.co introduced AI-Driven KYC technology to enhance global e-commerce transactions through ShipToBox.com.',
    date: 'Apr 24, 2024',
    blog_url: `https://www.prnewswire.com/news-releases/paytomeco-launches-ai-driven-kyc-to-power-global-e-commerce-with-shiptoboxcom-302124750.html`,
    img_src: ShipToBox
  },
  {
    title: 'PayToMe.co featured in the Authoritative Nilson Report, a leading voice in the card payment sector. ',
    date: 'April 16, 2024',
    blog_url: `https://www.prnewswire.com/news-releases/paytomeco-featured-in-the-authoritative-nilson-report-1260-302116743.html`,
    img_src: NilsonReport
  },
  {
    title:
      'PayToMe.co is proud to announce its official partnership with Plaid, a leading platform in the financial technology ecosystem. ',
    date: 'March 11, 2024',
    blog_url: `https://www.prnewswire.com/news-releases/paytomeco-elevates-fintech-ecosystem-as-official-plaid-partner-302085298.html`,
    img_src: Partnership
  },
  {
    title:
      'AppTech Drives Licensing Revenues as Patent Licensee PayToMe.co Elevates Financial Technology Landscape with Plaid Integration.',
    date: 'February 01, 2024',
    blog_url: `https://apptechcorp.com/press-release/licensing-revenues-patent-licensee-paytome-co/`,
    img_src: PlaidIntegration
  },
  {
    title:
      'AppTech Payments Corp. Continues Global Expansion with Partner and Licensee PayToMe.co Opening New Office in Egypt.',
    date: 'September 21, 2023',
    blog_url: `https://apptechcorp.com/press-release/apptech-payments-corp-continues-global-expansion-with-partner-and-licensee-paytome-co-opening-new-office-in-egypt/`,
    img_src: GlobalExpension
  },
  {
    title:
      'PayToMe.co Has Earned The Coveted International Business Awards (2023 Stevie Award) for "Tech Startup of the Year - Services"',
    date: 'August 14, 2023',
    blog_url: `https://apptechcorp.com/press-release/apptech-payments-corp-patent-licensee-paytome-co-wins-bronze-stevie-award-in-2023-international-business-awards/`,
    img_src: WiningAward
  },
  {
    title: 'AppTech Payments Corp. Grants Patent License to Emerging Fintech Innovator PayToMe.co',
    date: 'June 27, 2023',
    blog_url:
      'https://apptechcorp.com/press-release/apptech-payments-corp-grants-patent-license-to-emerging-fintech-innovator-paytome-co/',
    img_src: PayToMeNews
  }
]
