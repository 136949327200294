import React from 'react'
import Image from 'next/image'
import parse from 'html-react-parser'
import { SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation } from 'swiper'
import { Box, CardContent, Card, Typography } from '@mui/material'

import useResponsive from '@/hooks/responsive'
import { HeaderTypography } from '@/custom-styles'
import { PTM_NEWS } from '@/components/news/constant'
import CustomLink from '@/custom-components/CustomLink'
import { SliderNextIcon, SliderPrevIcon } from '@/v1/icons'
import PrimarySlider from '@/custom-components/sliders/PrimarySlider'
import TailwindContainer from '@/custom-components/containers/TailwindContainer'

const News = ({ title = 'News' }) => {
  const { tabLandscape } = useResponsive()

  return (
    <>
      <TailwindContainer>
        <Typography variant={'h1'} sx={{ ...HeaderTypography }}>
          {title}
        </Typography>
        <p className='text-center text-5xl font-semibold leading-10 text-blue-700'></p>

        <div className='container relative max-w-[1340px] pt-4 md:pt-10'>
          <PrimarySlider
            slidesPerView={3}
            spaceBetween={5}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false
            }}
            modules={[Autoplay, Navigation]}
            loop={true}
            navigation={{
              prevEl: !tabLandscape ? '.custom-prev' : '',
              nextEl: !tabLandscape ? '.custom-next' : ''
            }}
            className='news-swiper'
            breakpoints={{
              375: {
                slidesPerView: 1.15,
                spaceBetween: 0
              },
              768: {
                slidesPerView: 2
              },
              1024: {
                slidesPerView: 3
              }
            }}
          >
            {PTM_NEWS.length > 0 &&
              PTM_NEWS.map((eachNews, index) => {
                return (
                  <SwiperSlide key={index}>
                    <Card
                      sx={{
                        position: 'relative',
                        boxShadow: '5px 3px 15px 10px rgba(58, 57, 78, 0.07)',
                        borderRadius: '10px',
                        margin: { lg: '20px', xs: '10px' }
                      }}
                    >
                      <CardContent
                        sx={{
                          p: '0 !important'
                        }}
                      >
                        <div
                          className={
                            'aspect-h-7 aspect-w-13 mb-4 w-[324px] overflow-hidden sm:w-[348px] lg:w-[305px] xl:mb-6 xl:w-[370px] 2xl:w-[398px]'
                          }
                        >
                          <Image
                            src={eachNews.img_src}
                            alt={'New Thumb'}
                            priority={true}
                            fill={true}
                            className={'h-full w-full object-cover object-center'}
                          />
                        </div>

                        <Box sx={{ px: '22px' }}>
                          <p className='pb-[10px] text-[13px] font-normal text-neutral-800'>{eachNews.date}</p>
                          <p className='min-h-[140px] text-base font-normal text-neutral-800 sm:min-h-[120px] xl:min-h-[90px]'>
                            {parse(eachNews.title)}
                          </p>

                          <Box
                            sx={{
                              pb: 10,
                              pt: 5,
                              ['a']: {
                                fontWeight: 600,
                                fontSize: '14px',
                                textDecoration: 'none',
                                color: '#2C28A2 !important',
                                borderBottom: '1px solid #2C28A2'
                              }
                            }}
                          >
                            <CustomLink openNewTab href={eachNews?.blog_url || '#'}>
                              Read full post
                            </CustomLink>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </SwiperSlide>
                )
              })}
          </PrimarySlider>

          {/* Custom navigation buttons */}
          {!tabLandscape && (
            <>
              <div className='custom-prev absolute top-[50%] cursor-pointer p-2 md:left-[-25px] xl:left-[-50px]'>
                <SliderPrevIcon />
              </div>
              <div className='custom-next absolute top-[50%] cursor-pointer p-2 md:right-[-25px] xl:right-[-50px]'>
                <SliderNextIcon />
              </div>
            </>
          )}
        </div>
      </TailwindContainer>
    </>
  )
}

export default News
