import { styled } from '@mui/material/styles'
import MuiTabList from '@mui/lab/TabList'
import Switch from '@mui/material/Switch'
import styleConfig from '../configs/styleConfig'
import Box from '@mui/material/Box'
// @ts-ignore
import { BoxProps } from '@mui/system'
import { FC } from 'react'
import { Popover, Theme, TypographyProps } from '@mui/material'
import Typography from '@mui/material/Typography'

const { colors, typographyStyle } = styleConfig

export const CustomTabList = styled(MuiTabList)(({ theme }) => ({
  minHeight: 40,
  marginBottom: theme.spacing(4),
  textTransform: 'capitalize',

  '& .MuiTabs-indicator': {
    display: 'none'
  },
  '& .MuiTab-root': {
    ...typographyStyle,
    textTransform: 'capitalize !important',
    minWidth: 65,
    minHeight: 40,
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    borderRadius: theme.shape.borderRadius,
    '&.Mui-selected': {
      color: theme.palette.common.white,
      backgroundColor: colors.purple
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 130
    }
  }
}))

export const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPaper-root': {
    overflowX: 'unset',
    overflowY: 'unset',

    '&::before': {
      content: '""',
      position: 'absolute',
      marginRight: '-0.71em',
      bottom: -8,
      left: '50%',
      transform: 'translate(-50%, -50%) rotate(135deg)',
      width: 8,
      height: 8,
      // backgroundColor: '#7F7F7F',
      backgroundColor: '#0000F6',
      clipPath: 'polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))'
    }
  }
}))

export const PhoneInputStyles = {
  ['.react-tel-input > input.form-control']: {
    width: '100% !important',
    height: '40px !important',
    borderColor: `${colors.purple} !important`,
    borderRadius: '6px',
    color: `${colors.black} !important`,
    fontWeight: '700 !important',
    fontSize: '14px !important'
  },

  ['.react-tel-input .flag-dropdown']: {
    borderColor: `${colors.purple} !important`
  },

  ['.react-tel-input .country-list']: {
    bottom: '30px'
    // width: '350px !important',
    // border: `1px solid ${colors.purple}`,
    // borderRadius: '6px',
  }
}

export const MuiPhoneInputStyles = {
  ['.react-tel-input > input.form-control']: {
    width: '100% !important',
    height: '40px !important',
    borderColor: `${colors.purple} !important`,
    borderRadius: '6px',
    fontSize: '14px !important',
    color: `${colors.black} !important`
  },

  ['.react-tel-input .flag-dropdown']: {
    borderColor: `${colors.purple} !important`
  },

  ['.react-tel-input .special-label']: {
    fontSize: '12px',
    fontWeight: 600,
    top: '-10px',
    left: '10px',
    color: '#000'
  },

  ['.react-tel-input .asterisk']: {
    color: '#f00',
    fontWeight: 800
  },
  ['.react-tel-input .country-list']: {
    // bottom: '10px'
    // width: '350px !important',
    // border: `1px solid ${colors.purple}`,
    // borderRadius: '6px',
  }
}

export const CardStyle = {
  overflow: 'unset',
  border: '1px solid #ddd',
  borderRadius: '6px'
}

export const StyledSwitch = styled(Switch)({
  width: 100,
  height: 50,
  padding: 0,
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  cursor: 'pointer',
  '& .MuiSwitch-switchBase': {
    padding: 1,
    '&.Mui-checked': {
      transform: 'translateX(30px)',
      color: 'white',
      '& + .MuiSwitch-track': {
        backgroundColor: '#2196f3',
        opacity: 1,
        border: 'none'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    width: 24,
    height: 24
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#ccc',
    opacity: 1,
    transition: 'background-color border'
  },
  '& .MuiSwitch-label': {
    fontSize: 12,
    position: 'absolute',
    zIndex: 1,
    color: 'white',
    userSelect: 'none',
    pointerEvents: 'none'
  },
  '& .MuiSwitch-left': {
    left: 6
  },
  '& .MuiSwitch-right': {
    left: 27
  }
})

export const PoppinsTypography = {
  fontFamily: 'Poppins',
  fontStyle: 'normal'
}

export const HeaderTypography = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  color: '#0600FF',
  textAlign: 'center',
  fontSize: { xs: '24px', md: '48px' },
  fontWeight: 600,
  lineHeight: { xs: '130%', md: '127%' }
}

export const InterTypography = {
  fontFamily: 'var(--font-inter)',
  fontStyle: 'normal'
}

export const BodyStyle = {
  m: '0 auto 40px',
  color: '#011B33',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: { xs: '16px', lg: '16px' },
  lineHeight: '22px',
  letterSpacing: '0.3px',
  display: { xs: 'none', md: 'block' },
  ['p, li, span']: {
    color: '#011B33',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: { xs: '16px', lg: '16px' },
    fontStyle: 'normal',
    lineHeight: '22px'
  }
}

export const AccordionStyles = {
  ['.accordion-title, .accordion-title-icon, .info-icon']: { color: '#0000F6' }
}

export const DataGridStyles = {
  ['.MuiDataGrid-columnHeader']: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    minWidth: { xs: '150px !important', lg: 'unset !important' }
  },
  ['.MuiDataGrid-columnHeaders']: {
    minHeight: '30px !important',
    maxHeight: '35px !important'
  },
  ['.MuiDataGrid-cell, .MuiDataGrid-row']: {
    minHeight: '35px !important',
    maxHeight: '40px !important',
    minWidth: {
      xs: '150px !important',
      md: '207px !important',
      lg: '151px !important',
      xl: '173px !important'
    }
  },
  ['.MuiDataGrid-virtualScrollerContent']: {
    minWidth: 500
  },

  ['.MuiDataGrid-cell:last-child']: {
    padding: '0 !important',
    minWidth: {
      xs: '50px !important'
    }
  }
}

export const DataGridStylesV2 = {
  ['.MuiDataGrid-columnHeader']: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important'
  },

  ['.MuiDataGrid-columnHeaders']: {
    minHeight: '30px !important',
    maxHeight: '35px !important'
  },

  ['.MuiDataGrid-cell, .MuiDataGrid-row']: {
    minHeight: '35px !important',
    maxHeight: '40px !important'
  },

  ['.MuiDataGrid-virtualScrollerContent']: {
    // minHeight: '115px !important'
  },

  ['.MuiDataGrid-cell:last-child']: {
    padding: '0 !important'
  }
}

export const InfoBox: FC<BoxProps> = styled(Box)<{}>({
  border: '1px solid #B7B7B7',
  borderRadius: '5px',
  padding: '10px'
})

export const BannerTitleSlug: FC<TypographyProps> = styled(Box)<{}>({
  color: '#0600F9',
  fontFamily: 'Inter',
  fontSize: '40px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '72.8px',
  letterSpacing: '-0.84px',
  textTransform: 'capitalize'
})

export const BannerTitle: FC<BoxProps> = styled(Box)<{}>({
  color: '#022A4E',
  fontFamily: 'Inter',
  fontSize: '79px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '72.8px',
  letterSpacing: '-3.84px',
  textTransform: 'capitalize'
})

export const BannerSubtitle: FC<BoxProps> = styled(Typography)<{}>({
  color: '#4D5499',
  fontFamily: 'Inter',
  fontSize: '32px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '42px',
  wordBreak: 'break-all'
})

export const MaxWidth1288: FC<BoxProps> = styled(Box)<{}>({
  maxWidth: 1288,
  margin: 'auto'
})

export const RightWrapper: FC<BoxProps> = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(6),
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('lg')]: {
    maxWidth: 480
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: 635
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(12)
  }
}))
